'use strict';

angular.module('enervexSalesappApp').controller('AccountRequestCtrl', function($scope, $stateParams, $state, Auth, User, AccountRequest, AccountRequestComment, AccountRequestsService, Util, FileUploader, _) {
	var getCurrentUser = Auth.getCurrentUser;
	$scope.isAdmin = Auth.isAdmin;

	$scope.accountId = $stateParams.accountId;
	$scope.accountRequestId = $stateParams.accountRequestId;
	$scope.newComment = {
		// notifyMembers:[]
	};
	Util.setNotifyMembersType($scope.newComment, $scope.job)
	$scope.account = {};
	$scope.commentOptions = {
		target: "Account Request",
		hideWho: true
	}
	// var uploader = $scope.uploader = new FileUploader({});

	if ($scope.accountRequestId !== 'new'){
		$scope.accountRequest = AccountRequest.get({
			id: $stateParams.accountRequestId
		});
		$scope.accountRequestComments = AccountRequestComment.query({
			accountRequestId:  $stateParams.accountRequestId
		});
	}
	$scope.submitRequest = function(account) {
		AccountRequest.save({}, {
			name: account.name,
			prefixCode: account.prefixCode,
			pricingIndex: Number(account.pricingIndex),
			accountType: account.accountType
		}).$promise.then(function(res){
			$state.go('accounts', {
			}, {
				reload: true,
				inherit: false
			})
		});
	};

	function fetchComments(){
		$scope.accountRequestComments = AccountRequestsService.getComments();
	}
	$scope.submitComment = function(newComment) {
		// if (newComment.notifyMembersType === 'all'){
		// 	newComment.notifyMembers = _.map($scope.job.members, function(member){
		// 		return member.user._id;
		// 	});
		// } else if (newComment.notifyMembersType === 'some' ) {
		// 	newComment.notifyMembers = $scope.checkedEmailMembers;
		// } else if (newComment.notifyMembersType === 'none'){
		// 	newComment.notifyMembers = [];
		// }
		if ($scope.uploader && $scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				// fd.append('visibility', $scope.design.visibility);
				promises.push(DesignService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				AccountRequestsService.makeComment(newComment).$promise.then(function(res) {
					$scope.newComment = {
						// notifyMembers:[]
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					if ($scope.uploader){
						$scope.uploader.queue = [];
					}
					fetchComments();
				});
			})
		} else {
			AccountRequestsService.makeComment(newComment).$promise.then(function(res) {
				if ($scope.uploader){
					$scope.uploader.queue = [];
				}
				$scope.newComment = {
					// notifyMembers:[]
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	$scope.saveCommentChange = function(comment){
		AccountRequestsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.approveRequest = function() {
		AccountRequestsService.approveRequest().$promise.then(function(){
			$state.go('accounts', {
			}, {
				reload: true,
				inherit: false
			})
		});
	};
	$scope.denyRequest = function() {
		AccountRequestsService.denyRequest().$promise.then(function(){
			$state.go('accounts', {
			}, {
				reload: true,
				inherit: false
			})
		});
	};
	// Not Implemented in Design/Mocks - holding off on html implementation
	$scope.deleteComment = function(comment){
		AccountRequestsService.removeComment(comment).$promise.then(function(){
			fetchComments();
		});
	};
});
